<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="700px">
      <div class="header">
        <div class="headtxt">
          <span
            >合计积分：<span class="red">{{
              dataItem.statistics.totalScore
            }}</span>
          </span>
          <span
            >已兑积分：<span class="red"
              >{{ dataItem.statistics.exchangeScore }} （佣金：￥{{
                dataItem.statistics.paymentAmount
              }}）</span
            ></span
          >
          <span
            >平台服务费积分：<span class="red">{{
              dataItem.statistics.platformScore
            }}</span></span
          >
          <span
            >剩余积分：<span class="red">{{
              dataItem.statistics.leftScore
            }}</span></span
          >
        </div>
      </div>
      <div style="text-align: right; margin: 10px 0px">
        <el-button type="primary" size="small" @click="handleExport"
          >下载</el-button
        >
      </div>
      <div class="chain">
        <div id="pdfPage" style="padding: 10px">
          <div class="headtxt" style="margin-bottom: 20px" v-show="oncotsd">
            <span
              >合计积分：<span class="red">{{
                dataItem.statistics.totalScore
              }}</span>
            </span>
            <span
              >已兑积分：<span class="red"
                >{{ dataItem.statistics.exchangeScore }} （佣金：￥{{
                  dataItem.statistics.paymentAmount
                }}）</span
              ></span
            >
            <span
              >平台服务费积分：<span class="red">{{
                dataItem.statistics.platformScore
              }}</span></span
            >
            <span
              >剩余积分：<span class="red">{{
                dataItem.statistics.leftScore
              }}</span></span
            >
          </div>
          <div
            class="chaincard"
            v-for="(value, key) in dataItem.list"
            :key="key"
          >
            <div class="cardtitle">{{ key }}</div>
            <div v-for="(item, index) in value" :key="index" class="cardbotsd">
              <div class="carhead">{{ item.taskName }}</div>
              <div v-for="(v, i) in item.data" :key="i" class="cardbody">
                <div class="cardbodone">
                  <div class="cardbod1">{{ v.finishTime }}</div>
                  <div class="cardbod2">
                    {{ v.taskType == 19 ? v.remark : v.customerName }}
                  </div>
                  <div class="cardbod3">
                    +<span class="red">{{ v.score }}</span
                    >积分
                  </div>
                </div>
                <div
                  v-if="[14, 15, 16].includes(item.taskId)"
                  class="cardbodone"
                  style="margin-top: 10px"
                >
                  <div class="cardbod1"></div>
                  <div class="cardbod2" style="color: #000">
                    订单号：<span style="margin-right: 10px">{{
                      v.orderId
                    }}</span>
                    付款金额 ：<span class="red">￥{{ v.amount }}</span>
                  </div>
                  <div class="cardbod3"></div>
                </div>
                <div
                  v-if="[11].includes(item.taskId)"
                  class="cardbodone"
                  style="margin-top: 10px"
                >
                  <div class="cardbod1"></div>
                  <div class="cardbod2" style="color: #000">
                    订单号：<span style="margin-right: 10px">{{
                      v.orderId
                    }}</span>
                    <span>{{ v.remark }}</span>
                  </div>
                  <div class="cardbod3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  salesmandetail, //业务员佣金明细
} from "@/api/apiAll/phpUrl";
import PdfLoader from "@/utils/html2pdf.js";
export default {
  name: "",
  data() {
    return {
      dialogVisible: false,
      title: "",
      dataItem: {
        list: [],
        statistics: {}, //统计数据
      },
      oncotsd: false,
    };
  },
  methods: {
    async init(salesmanId, paymentMonth, title) {
      this.title = title;
      let res = await salesmandetail({
        salesmanId,
        paymentMonth,
      });
      if (res.code == 200) {
        this.dataItem = await this.$options.data().dataItem;
        await Object.assign(this.dataItem, res.data);
        this.dialogVisible = true;
      }
    },
    async handleExport() {
      if (this.dataItem.list.length == 0)
        return this.$message.error("没有数据");
      const loading = this.$loading({
        lock: true,
        text: "保存中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.oncotsd = true;
      let pdf = await new PdfLoader(
        document.querySelector("#pdfPage"),
        this.title,
        "cardbotsd"
      );
      pdf.outPutPdfFn(this.title).then(() => {
        this.oncotsd = false;
        loading.close();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.headtxt {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
}
.headtxt > span {
  margin-right: 15px;
  font-size: 12px;
}
.header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 10px;
}
.chain {
  max-height: 550px;
  background-color: #f2f2f2;
  overflow: auto;
  .chaincard {
    margin-bottom: 30px;
    color: #000;
    .cardtitle {
      width: 100%;
      font-weight: bold;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: dashed 1px #ddd;
      display: flex;
      align-items: center;
    }
    .cardtitle::before {
      width: 10px;
      height: 10px;
      background-color: #06b7ae;
      border-radius: 10px;
      display: inline-block;
      content: "";
      margin-right: 5px;
    }
    .cardbotsd {
      margin-bottom: 20px;
      .carhead {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: -10px;
      }
      .cardbody {
        color: #606266;
        .cardbodone {
          display: flex;
          justify-content: space-around;
          margin-top: 25px;
          .cardbod1 {
            width: 10%;
          }
          .cardbod2 {
            width: 75%;
            padding: 0px 10px;
          }
          .cardbod3 {
            width: 15%;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="merch">
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-date-picker
          v-model="queryParams.datelist"
          type="monthrange"
          range-separator="-"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          unlink-panels
          value-format="yyyy-MM"
        />
      </el-form-item>
      <el-form-item prop="keyword">
        <el-input
          maxlength="32"
          v-model="queryParams.keyword"
          placeholder="业务员名称/手机号"
          clearable
          style="width: 200px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查 询</el-button>
        <el-button type="primary" @click="Downloadcomreport"
          >下载佣金报表</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      border
      :data="salecommList"
      ref="commTable"
      :header-cell-style="{ background: '#F2F3F5' }"
      height="580px"
    >
      <el-table-column label="统计月份" align="center" prop="paymentMonth" />
      <el-table-column
        label="业务员名称"
        align="center"
        prop="bankAccountName"
      />
      <el-table-column
        label="业务员佣金（元）"
        align="center"
        prop="paymentAmount"
      />
      <el-table-column label="业务类型" align="center" prop="businessType" />
      <el-table-column label="证件号" align="center" prop="idNumber" />
      <el-table-column
        label="收款账号"
        align="center"
        prop="bankAccountNumber"
      />
      <el-table-column label="收款银行名称" align="center" prop="bankName" />
      <el-table-column label="手机号" align="center" prop="mobile" />
      <el-table-column
        label="项目/产品推广"
        align="center"
        prop="projectType"
      />
      <el-table-column label="打款状态" align="center" prop="status" />
      <el-table-column label="佣金任务证据链" align="center" width="150">
        <template slot-scope="{ row }">
          <div>
            <span class="btsed" @click="Viewchain(row)">查看证据链</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <Evidencechain ref="evidenrs" />
  </div>
</template>

<script>
import {
  salesmanlist, //业务员佣金打款记录列表
  salesmanlistExport, //业务员佣金打款记录导出
} from "@/api/apiAll/phpUrl";
import Evidencechain from "./components/Evidencechain.vue";
import { downLoadZip } from "@/utils/zipdownload";
export default {
  name: "",
  components: { Evidencechain },
  data() {
    return {
      loading: false, // 加载状态
      queryParams: {
        page: 1,
        perPage: 10,
        datelist: [],

        startMonth: "",
        endMonth: "",
        keyword: "",
      },
      salecommList: [], // 列表数据
      total: 0, // 总条数
    };
  },
  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    //获取列表数据
    this.getList();
  },
  methods: {
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    getList() {
      var data = { ...this.queryParams };
      if (data.datelist) {
        data.startMonth = data.datelist[0];
        data.endMonth = data.datelist[1];
      } else {
        data.startMonth = "";
        data.endMonth = "";
      }
      this.loading = true;
      salesmanlist(data).then((res) => {
        this.salecommList = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
      this.setquery();
    },
    // 查询
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    // 下载佣金报表
    Downloadcomreport() {
      downLoadZip(
        `/salesmanCommisionProof/listExport`,
        "xlsx",
        "佣金报表",
        this.queryParams,
        "php",
        "get"
      );
    },
    /** 查看证据链 */
    Viewchain(row) {
      var title =
        row.paymentMonth + "—" + row.bankAccountName + "任务佣金证据链";
      this.$refs.evidenrs.init(row.salesmanId, row.paymentMonth,title);
    },
  },
};
</script>
<style lang="scss" scoped></style>
